.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 7vh;
  align-items: center;
  /* font-size: 1rem; */
  color: white;
}

p{
  line-height: 1.2;
}

.disable-link{
  pointer-events: none;
}

a {
  opacity: 1;
  transition: opacity 0.3s ease;
}

@media (hover: hover) {
  a:hover {
    opacity: 0.6;
  }
}



